import React from "react"

const Hightlight = ({title, description, image}) => {
    return (
      <article className="highlight">
        <div className="w-40 h-40 mb-4">
          <img className="object-contain" src={image.src} width={image.width} height={image.height} alt=""/>
        </div>
          <h3 className="font-semibold text-xl mb-2">{title}</h3>
          <p className="text-gray-700">
          {description}
          </p>
      </article>
    );
  }


const Highlights = () => {
    const highlights = [
        {
            image: {src: '/images/illustrations/quality.svg', height: '122', width: '150'},
            title: 'Zawsze najwyższa jakość',
            description: 'Stawiamy na jakość, a nie ilość. Ma być najlepiej, a nie najwięcej. Bez kompromisów!'
        },

        {
            image: {src: '/images/illustrations/program.svg', width: '150', height: '108'},
            title: 'Profesjonalnie dobrany Program',
            description: 'Agendy naszych Szkoleń to efekt tysięcy wypełnionych ankiet i wieloletnich doświadczeń najlepszych specjalistów.'
        },

        {
            image: {src: '/images/illustrations/experts.svg', width: '112', height: '150'},
            title: 'Najlepsi eksperci — praktycy',
            description: 'Topowi polscy programiści, architekci, konsultanci i trenerzy. Pracujemy wyłącznie z najlepszymi z najlepszych!'
        },

        {
            image: {src: '/images/illustrations/formats.svg', width: '150', height: '88'},
            title: 'Dogodne formaty treści',
            description: 'Zawsze oferujemy materiały we wszystkich możliwych formatach: video, audio, tekst, slajdy... Rozwijaj się na różne sposoby!'
        },

        {
            image: {src: '/images/illustrations/practice.svg', width: '150', height: '124'},
            title: 'Skupienie na praktyce',
            description: 'U nas zawsze otrzymasz kod, zadania domowe i masę praktycznych materiałów do wdrożenia w swoich projektach.'
        },
        {
            image: {src: '/images/illustrations/mentors.svg', width: '138', height: '150'},
            title: 'Społeczność i Mentorzy',
            description: 'Wspólna nauka w społeczności każdego szkolenia i interakcja z Mentorami. W grupie raźniej i bardziej efektywnie!'
        }
    ]

    return <section className="ds-container">
        <h2 className="ds-heading">Co nas wyróżnia?</h2>
        <p className="ds-heading-description">
            Wydajemy najwyższej jakości szkolenia online, kierowane do wymagających specjalistów.

            Współpracujemy wyłącznie z najlepszymi, uznanymi Ekspertami. To praktycy z olbrzymim doświadczeniem, jednocześnie potrafiący przekazać wiedzę w przystępny sposób. Dbamy przede wszystkim o merytorykę materiałów i ich bezpośrednie przełożenie na rozwój kariery Uczestników naszych Programów!
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 gap-y-20">
           {highlights.map((item, index) => <Hightlight key={index} {...item}/>)}
        </div>
    </section>
}

export default Highlights;