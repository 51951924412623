import React from "react";
import Image from "./image";

const companiesData = [
  { name: "Google", logo: "logos/Logo.svg" },
  { name: "Netguru", logo: "logos/Logo-1.svg" },
  { name: "Monterail", logo: "logos/Logo-2.svg" },
  { name: "10Clouds", logo: "logos/Logo-3.svg" },
  { name: "Docplanner", logo: "logos/Logo-4.svg" },
  { name: "tonik", logo: "logos/Logo-5.svg" },
  { name: "EL Passion", logo: "logos/Logo-6.svg" },
  { name: "Packhelp", logo: "logos/Logo-7.svg" },
  { name: "intent", logo: "logos/Logo-8.svg" },
  { name: "Brainly", logo: "logos/Logo-9.svg" },
  { name: "Future Mind", logo: "logos/Logo-10.svg" },
  { name: "Allegro", logo: "logos/Logo-11.svg" },
];

const Companies = () => (
  <section className="ds-container">
    <h2 className="ds-heading">Zaufały nam najlepsze firmy IT</h2>
    <p className="ds-heading-description">
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
      cubilia Curae; Fusce id purus. Praesent blandit laoreet nibh. Praesent
      egestas neque eu enim. Phasellus tempus.{" "}
    </p>
    <ul className="grid grid-cols-2 sm:grid-cols:4 md:grid-cols-6">
      {companiesData.map((company, index) => (
        <li key={index}>
          <Image src={company.logo} alt={company.name} />
        </li>
      ))}
    </ul>
  </section>
);

export default Companies;
