import React from "react"
import { graphql, StaticQuery } from "gatsby"
import {
  GatsbyImage
} from "gatsby-plugin-image"


export const Image = ({
  src,
  height,
  width,
  margin,
  objectFit = "contain",
  full,
  objectPosition,
  className,
  alt
}) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { internal: { mediaType: { regex: "/image/" } } }
          ) {
            edges {
              node {
                relativePath
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const match = data.images.edges.find(
          ({ node }) => src === node.relativePath
        )
        if (!match) return null
        const { node: { childImageSharp, publicURL, extension } = {} } = match
        if (extension === "svg" || !childImageSharp.gatsbyImageData) {
          return <img width={width} height={height} alt="" className={className} src={publicURL}  />
        }
        return (
          <GatsbyImage
            objectFit={objectFit}
            objectPosition={objectPosition}
            className={className}
            image={childImageSharp.gatsbyImageData}
            alt={alt || ""}
          />
        )
      }}
    />
  )
}

export default Image
