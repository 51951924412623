import React from "react";
import Image from "./image";

const DevStyle = () => (
  <div className="flex items-center justify-center py-5 bg-gray-100 sm:py-40 px-10">
    <a
      target="_blank"
      rel="noreferrer"
      href="https://devstyle.pl"
      title="devstyle.pl"
    >
      <Image
        width="462"
        height="96"
        alt="devstyle.pl"
        src="content/devstyle.png"
      />
    </a>
  </div>
);

export default DevStyle;
