import React from "react";
import Image from "./image";

const Review = ({ name, position, picture, description }) => (
  <div>
    <article className="overflow-hidden  rounded-3xl bg-white shadow-2xl px-8 py-8">
      <header className="flex items-center mb-6">
        <Image width="40" height="40" src={picture} alt="" className="w-10 h-10 rounded-full mr-2" />
        <h3 className="text-sm font-semibold">
          {name}
          <span className="block font-normal opacity-70 text-xs">
            {position}
          </span>
        </h3>
      </header>
      <p>{description}</p>
    </article>
  </div>
);

const reviewsData = [
    {
      name: "Tomasz",
      position: "Architektura Na Froncie",
      picture: "content/person.svg",
      description: "Prowadzący potrafił rzetelnie przedstawić materiał, potrafił przyśmieszkować, to też bardzo doceniam. No i tematyka była  w sam raz dla mnie. Szukałem właśnie czegoś z wyższego szczebla i ANF właśnie to mi zapewnił. Lubię tak trochę surowo w kodzie posiedzieć, a trochę podywagować nad tym kiedy i gdzie to zastosować.",
    },
  {
    name: "Marek",
    position: "Droga Nowoczesnego Architekta",
    picture: "content/person.svg",
    description:
      "Nie ma na rynku tak kompletnego, poruszającego tak wiele aspektów związanych z architekturą oprgramowania kursu czy szkolenia. Wszystko zgromadzone w jednym miejscu, usystematyzowane - nic tylko brać i się uczyć.",
  },
  {
    name: "Sebastian",
    position: "DBMaster",
    picture:  "content/person.svg",
    description: "Szkoda czasu na rozwlekłe recenzje i nic nie mówiące pitu pitu. DBMaster to samo mięso, Damian przechodzi od razu do meritum, tak też i ja zrobię. Kurs jest dla ludzi z pewnym poziomem wiedzy, zatem podstawy, których pełno w innych kursach są tutaj zgrabnie pominięte, a w zamian zostajemy obrzuceni zaawansowanymi technikami, niskopoziomową analizą i tipsami od wieloletniego praktyka. To chyba najpiękniejsze w szkoleniu.",
  },
  {
    name: "Łukasz",
    position: "SmartTesting",
    picture:  "content/person.svg",
    description: "SmartTesting to świetny program przekazujący ogrom wiedzy związanej z testowaniem w przystępny i ciekawy sposób. Niebanalna forma pozwala na większe skupienie i lepsze przyswajanie przekazywanych informacji. Z kolei profesjonalizm i ogromne doświadczenie prowadzących sprawia, że merytoryka reprezentuje najwyższy poziom. Wiedzę, którą zdobyłem od samego początku wykorzystuję w praktyce, a to daje mi dużo satysfakcji.",
  }
];

const Reviews = () => (
  <section className="ds-container">
    <h2 className="ds-heading">Co Absolwenci mówią o naszych Szkoleniach?</h2>
    <p className="ds-heading-description">
      Nasze Programy zbierają setki pozytywnych opinii. Zobacz kilka z nich!
    </p>

    <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 gap-6 lg:gap-10">
      {reviewsData.map((review, index) => (
        <Review key={index} {...review} />
      ))}
    </div>
  </section>
);

export default Reviews;
