import React from "react";

const Hero = () => (
  <div className="bg-black overflow-hidden">
    <div className="text-white max-w-7xl mx-auto px-4 pt-5 pb-6 lg:pt-14 lg:pb-24 flex justify-between items-center flex-wrap lg:flex-nowrap">
      <div className="w-full">
        <h1 className="text-orange-500 text-4xl lg:text-7xl font-bold mb-4">
          szkolenia @ devstyle
        </h1>
        <p className="text-2xl lg:text-3xl font-semibold leading-8 mb-4">
        Szkolenia devstyle — rozwijaj swoją dev-karierę!
                </p>
        <p className="opacity-70 text-base lg:text-xl leading-8 mb-11">
        Poznaj topowe polskie szkolenia online! Dołącz do kilkudziesięciu tysięcy zadowolonych subskrybentów, rozwiń swoją dev-pasję i zadbaj o programistyczną karierę! Najlepsi polscy Mentorzy i materiały czekają na Ciebie!

        </p>
        <a className="btn-white" href="#szkolenia">
        Wybierz szkolenie dla siebie »
        </a>
      </div>
      <img
        src="/images/illustrations/hero.svg"
        className="max-w-hero-img w-full mx-auto lg:transform lg:translate-x-11"
        alt=""
        width="620"
        height="620"
      />
    </div>
  </div>
);

export default Hero;
