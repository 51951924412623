// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-kontakt-mdx": () => import("./../../../src/pages/kontakt.mdx" /* webpackChunkName: "component---src-pages-kontakt-mdx" */),
  "component---src-pages-o-nas-mdx": () => import("./../../../src/pages/o-nas.mdx" /* webpackChunkName: "component---src-pages-o-nas-mdx" */),
  "component---src-pages-old-polityka-prywatnosci-mdx": () => import("./../../../src/pages/_old/polityka-prywatnosci.mdx" /* webpackChunkName: "component---src-pages-old-polityka-prywatnosci-mdx" */),
  "component---src-pages-old-regulamin-mdx": () => import("./../../../src/pages/_old/regulamin.mdx" /* webpackChunkName: "component---src-pages-old-regulamin-mdx" */)
}

