import Seo from "../../../../src/components/seo";
import Hero from "../../../../src/components/hero";
import Highlights from "../../../../src/components/highlights";
import Trainings from "../../../../src/components/trainings";
import Devstyle from "../../../../src/components/devstyle";
import Reviews from "../../../../src/components/reviews";
import Companies from "../../../../src/components/companies";
import * as React from 'react';
export default {
  Seo,
  Hero,
  Highlights,
  Trainings,
  Devstyle,
  Reviews,
  Companies,
  React
};